import React from 'react';

import { useDisclosure } from '@chakra-ui/react';

import { CustomModal } from '@components/custom-modal';
import { CustomText } from '@components/Custom-text';

import PopOverItem from './PopoverItem';
import { PopoverItem } from '../types';

type PopoverItemDataProps = {
    popoverData: PopoverItem[];
    onLogout: () => void;
};

const PopoverItemData = ({ popoverData, onLogout }: PopoverItemDataProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const modalBody = (
        <CustomText color="mainText" size="lg">
            Are you sure you want to Log out?
        </CustomText>
    );
    return (
        <>
            {popoverData.map(item => (
                <PopOverItem
                    icon={item.icon}
                    title={item.title}
                    path={item.path}
                    key={item.title}
                    onClick={item.onClick ? onOpen : () => {}}
                />
            ))}

            <CustomModal
                isLoading={false}
                onClose={onClose}
                isOpen={isOpen}
                onConfirmation={onClose}
                onCancel={() => {
                    onLogout();
                    onClose();
                }}
                showLoaderOnCancelBtn
                title="Logout"
                cancelBtnText="Yes, Log Out"
                confirmBtnText="No, Stay Logged In"
                confirmBtnColorScheme="blue"
                cancelBtnColorScheme="red"
                body={modalBody}
                isBodyReactNode
            />
        </>
    );
};

export default PopoverItemData;
