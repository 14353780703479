import React from 'react';

import {
    Avatar,
    Flex,
    List,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    VStack,
} from '@chakra-ui/react';
import Link from 'next/link';

import ActiveBadge from '@components/active-badge/ActiveBadge';
import { CustomText } from '@components/Custom-text';
import { AccountStatus, UserType } from '@enums/index';
import { useAppDispatch } from '@hooks/store';
import useAuthSelector from '@hooks/useAuthSelector';
import { setLogout } from '@modules/auth/lib/authSlice';
import { paths } from '@routes/path';

import { useMenteePopoverData } from './MenteePopOverData';
import { useMentorPopoverData } from './MentorPopOverData';
import PopoverItemData from './PopoverItemData';

type AccountPopoverProps = {
    onClose: () => void;
};
const AccountPopover = ({ onClose }: AccountPopoverProps) => {
    const dispatch = useAppDispatch();
    const logout = () => dispatch(setLogout());
    const mentorPopoverData = useMentorPopoverData();
    const menteePopoverData = useMenteePopoverData();
    const { user } = useAuthSelector();
    return (
        <PopoverContent mt={3} p={2}>
            <PopoverHeader>
                <Flex gap={4} alignItems="center">
                    <Avatar name={user?.name} src={user?.profileImageUrl ?? ''}>
                        {user?.userType === UserType.Mentor &&
                            user?.accountStatus === AccountStatus.Active && (
                                <ActiveBadge width={20} height={20} />
                            )}
                    </Avatar>
                    <VStack align="flex-start" gap={0} style={{ wordBreak: 'break-word' }}>
                        <CustomText size="lg" color="mainText" weight={500}>
                            {user?.name}
                        </CustomText>
                        {user?.userType === UserType.Mentee && (
                            <Link href={paths.mentee.dashboard.profile} onClick={onClose}>
                                <CustomText color="subText">View Profile</CustomText>
                            </Link>
                        )}
                    </VStack>
                </Flex>
            </PopoverHeader>
            <PopoverBody>
                <List spacing={5} width="100%" mt={4} onClick={onClose}>
                    {user?.userType === UserType.Mentor ? (
                        <PopoverItemData popoverData={mentorPopoverData} onLogout={logout} />
                    ) : (
                        <PopoverItemData popoverData={menteePopoverData} onLogout={logout} />
                    )}
                </List>
            </PopoverBody>
        </PopoverContent>
    );
};

export default AccountPopover;
