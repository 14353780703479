import React from 'react';

import { Avatar, Box, Flex, IconButton, Popover, PopoverTrigger } from '@chakra-ui/react';
import { HiOutlineChevronDown } from 'react-icons/hi2';

import ActiveBadge from '@components/active-badge/ActiveBadge';
import { CustomText } from '@components/Custom-text';
import { AccountStatus, UserType } from '@enums/index';
import useAuthSelector from '@hooks/useAuthSelector';

import AccountPopover from './AccountPopover';

const AccountInfo = () => {
    const { user } = useAuthSelector();
    return (
        <Flex gap={{ base: 1, sm: 1, md: 4 }} align="center">
            <Avatar size="sm" name={user?.name} src={user?.profileImageUrl ?? ''}>
                {user?.userType === UserType.Mentor &&
                    user?.accountStatus === AccountStatus.Active && <ActiveBadge />}
            </Avatar>
            <Flex gap={2} alignItems="center">
                <Box display={{ base: 'none', sm: 'none', md: 'block' }}>
                    <CustomText size="md" color="mainText">
                        {user?.name}
                    </CustomText>
                </Box>
                <Popover placement="bottom-start">
                    {({ onClose }) => (
                        <>
                            <PopoverTrigger>
                                <IconButton
                                    icon={<HiOutlineChevronDown />}
                                    aria-label="account-info"
                                    fontSize={20}
                                    bg="transparent"
                                    _hover={{ bg: 'transparent' }}
                                />
                            </PopoverTrigger>
                            <AccountPopover onClose={onClose} />
                        </>
                    )}
                </Popover>
            </Flex>
        </Flex>
    );
};

export default AccountInfo;
