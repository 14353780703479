import React from 'react';

import { As, Flex, ListIcon, ListItem } from '@chakra-ui/react';
import Link from 'next/link';

import { CustomText } from '@components/Custom-text';

interface PopoverItemProps {
    icon: As;
    title: string;
    path: string;
    onClick?: () => void;
}

const PopOverItem = ({ icon, title, path, onClick }: PopoverItemProps) => (
    <ListItem
        width="100%"
        py={2}
        mt="0px !important"
        onClick={onClick ? () => onClick() : () => {}}
        _hover={{ bg: 'popoverHover' }}
    >
        <Link href={path}>
            <Flex alignItems="center">
                <ListIcon as={icon} boxSize="20px" color="mainText" />
                <CustomText size="md" color="mainText">
                    {title}
                </CustomText>
            </Flex>
        </Link>
    </ListItem>
);

export default PopOverItem;
