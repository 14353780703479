import { useMemo } from 'react';

import { HiOutlineClock, HiOutlineQuestionMarkCircle, HiOutlineLogout } from 'react-icons/hi';

import { paths } from '@routes/path';

import { PopoverItem } from '../types';

export function useMentorPopoverData() {
    const PopOverData: PopoverItem[] = useMemo(
        () => [
            {
                icon: HiOutlineClock,
                title: 'Profile',
                path: paths.mentor.dashboard.profile,
            },
            {
                icon: HiOutlineQuestionMarkCircle,
                title: 'Help & Support',
                path: paths.mentor.dashboard.helpAndSupport,
            },
            {
                icon: HiOutlineLogout,
                title: 'Logout',
                path: '',
                onClick: () => {},
            },
        ],
        []
    );
    return PopOverData;
}
