import { useMemo } from 'react';

import {
    HiOutlineClock,
    HiOutlineQuestionMarkCircle,
    HiOutlineLogout,
    HiOutlineCalendar,
    HiOutlineCurrencyDollar,
    HiOutlineBell,
    HiOutlineCog,
} from 'react-icons/hi';

import { paths } from '@routes/path';

import { PopoverItem } from '../types';

export function useMenteePopoverData() {
    const PopOverData: PopoverItem[] = useMemo(
        () => [
            {
                icon: HiOutlineClock,
                title: 'Book Session',
                path: paths.general.findMentor,
            },
            {
                icon: HiOutlineCalendar,
                title: 'My Sessions',
                path: paths.mentee.dashboard.bookings,
            },
            {
                icon: HiOutlineCurrencyDollar,
                title: 'Billings and Payments',
                path: paths.mentee.dashboard.billings,
            },
            {
                icon: HiOutlineBell,
                title: 'Notifications',
                path: paths.mentee.dashboard.notifications,
            },
            {
                icon: HiOutlineCog,
                title: 'Settings',
                path: paths.mentee.dashboard.settings,
            },
            {
                icon: HiOutlineQuestionMarkCircle,
                title: 'Help & Support',
                path: paths.mentee.dashboard.helpAndSupport,
            },
            {
                icon: HiOutlineLogout,
                title: 'Logout',
                path: '',
                onClick: () => {},
            },
        ],
        []
    );
    return PopOverData;
}
