import React from 'react';

import Image from 'next/image';

import Badge from '@assets/ActiveBadge.svg';

type ActiveBadgeProps = {
    bottom?: number;
    right?: number;
    width?: number;
    height?: number;
};

const ActiveBadge = ({ height = 15, width = 15, bottom = -2, right = -2 }: ActiveBadgeProps) => (
    <Image
        style={{ position: 'absolute', bottom, right }}
        width={width}
        height={height}
        src={Badge}
        alt="Active Badge"
    />
);

export default ActiveBadge;
