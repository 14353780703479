import { ReactNode } from 'react';

import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ResponsiveValue,
} from '@chakra-ui/react';
import { HiLockClosed } from 'react-icons/hi';

import { CustomText } from '@components/Custom-text';

type CustomModalProps = {
    title: string | null;
    body: ReactNode | string;
    onClose: () => void;
    isOpen: boolean;
    onConfirmation?: () => void;
    onCancel: () => void;
    confirmBtnText: string;
    cancelBtnText: string;
    colorScheme?: string;
    isLoading: boolean;
    cancelBtnColorScheme?: string;
    confirmBtnColorScheme?: string;
    hideCloseBtn?: boolean;
    bodyFontSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl';
    showIcon?: boolean;
    textAlign?: ResponsiveValue<CanvasTextAlign> | undefined;
    cancelBtnTextColor?: string;
    showFooter?: boolean;
    isBodyReactNode?: boolean;
    showLoaderOnCancelBtn?: boolean;
    hideCancelBtn?: boolean;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
};
const CustomModal: React.FC<CustomModalProps> = ({
    title,
    body,
    onClose,
    isOpen,
    onConfirmation,
    onCancel,
    confirmBtnText,
    cancelBtnText,
    colorScheme,
    isLoading,
    cancelBtnColorScheme,
    confirmBtnColorScheme,
    hideCloseBtn,
    bodyFontSize = 'md',
    showIcon,
    textAlign = 'left',
    cancelBtnTextColor,
    showFooter = true,
    isBodyReactNode = false,
    showLoaderOnCancelBtn = false,
    hideCancelBtn = false,
    size,
}: CustomModalProps) => (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size={size}>
        <ModalOverlay />
        <ModalContent>
            {title && <ModalHeader fontWeight={500}>{title}</ModalHeader>}
            {!hideCloseBtn && <ModalCloseButton />}
            <ModalBody>
                {showIcon && (
                    <Flex width="100%" justify="center" py={2}>
                        <HiLockClosed size={50} color="gray" />
                    </Flex>
                )}
                {isBodyReactNode ? (
                    <>{body}</>
                ) : (
                    <CustomText textAlign={textAlign} size={bodyFontSize} color="mainText">
                        {body}
                    </CustomText>
                )}
            </ModalBody>
            {showFooter && (
                <ModalFooter gap={5}>
                    {!hideCancelBtn && (
                        <Button
                            fontWeight={400}
                            onClick={onCancel}
                            variant="outline"
                            isLoading={showLoaderOnCancelBtn ? isLoading : false}
                            colorScheme={cancelBtnColorScheme}
                            color={cancelBtnTextColor}
                        >
                            {cancelBtnText}
                        </Button>
                    )}
                    <Button
                        fontWeight={500}
                        isLoading={showLoaderOnCancelBtn ? false : isLoading}
                        onClick={onConfirmation}
                        colorScheme={confirmBtnColorScheme}
                        style={{ backgroundColor: confirmBtnColorScheme }}
                    >
                        {confirmBtnText}
                    </Button>
                </ModalFooter>
            )}
        </ModalContent>
    </Modal>
);

export default CustomModal;
